<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="quotation-id"
              v-model="filters.quotationId"
              name="quotationId"
              :label="$t('Id da cotação')"
              type="text"
            />
          </b-col>
          <b-col md="4">
            <e-store-combo
              id="quotation-store_id"
              v-model="filters.storeId"
              :required="false"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="quotation-price_table"
              v-model="filters.priceTableId"
              type="vue-select"
              :label="$t('Tabela de preço')"
              :placeholder="$t('Selecione')"
              :options="getComboPriceTables"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="quotation-delivery"
              v-model="filters.isDelivery"
              name="delivery"
              :label="$t('Entrega')"
              :placeholder="$t('Todos')"
              type="vue-select"
              :options="yesNoTypes()"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="5">
            <e-person-search
              id="quotation-customer_id"
              v-model="filters.customer"
              :label="$t('Cliente')"
              is-customer
              value-is-object
              :only-active="false"
              :default-options="defaultCustomerOptions"
              @input="setDefaultCustomerOptions"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="period"
              v-model="filters.createdDate"
              type="date-range-picker"
              :label="$t('Período criado')"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        id="quotation-table"
        ref="quotationTable"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('QUOTATION.NAME'), 'female')"
        :fields="fields"
        :items="quotations"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(delivery)="row">
          <e-status-badge
            :status="row.item.delivery"
            type="yesNo"
          />
        </template>
        <template #cell(consigned)="row">
          <e-status-badge
            :status="row.item.consigned"
            type="yesNo"
          />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="false"
            :show-delete="false"
            :show-activate="false"
            :show-deactivate="false"
            :buttons="[
              {
                icon: 'search',
                variant: 'primary',
                title: $t('Visualizar cotação'),
                event: 'quotation-read-only',
              },
            ]"
            @quotation-read-only="showQuotationReadOnly(row)"
            @update="showQuotationReadOnly(row)"
            @delete="deletePriceTable(row)"
            @activate="activateDeactivatePriceTable(row)"
            @deactivate="activateDeactivatePriceTable(row)"
          />
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="quotation-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="$can('Create', 'Quotation')"
      :main-tooltip="$t('Adicionar Cotação')"
      @click="onCreateQuotation"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EFilters,
  EGridActions,
  EStatusBadge,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { statusTypes } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import EPersonSearch from '@/views/components/inputs/EPersonSearch.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    EGridActions,
    EStatusBadge,
    EStoreCombo,
    EPersonSearch,
  },

  mixins: [statusTypes],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapGetters('common/priceTables', ['getComboPriceTables']),
    ...mapState('pages/sale/quotation', [
      'quotations',
      'sorting',
      'paging',
      'filters',
      'defaultCustomerOptions',
    ]),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          label: this.$t('Data'),
          key: 'createdDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px', minWidth: '100px' },
          sortable: true,
          formatter: val => this.$options.filters.datetime(val, '-'),
        },
        {
          label: this.$t('Id'),
          key: 'id',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { width: '300px' },
          formatter: val => this.$options.filters.storeName(val),
        },
        {
          label: this.$t('Cliente'),
          key: 'customer.name',
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Entrega'),
          key: 'delivery',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Consignado'),
          key: 'consigned',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Valor'),
          key: 'netValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '130px' },
          sortable: true,
          formatter: val => this.$options.filters.currency(val),
        },
      ]
    },
  },

  mounted() {
    this.fetchPriceTables()
    this.getData()
  },

  methods: {
    ...mapActions('common/priceTables', ['fetchPriceTables']),
    ...mapActions('pages/sale/quotation', [
      'fetchQuotations',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
      'setDefaultCustomerOptions',
    ]),
    ...mapActions('pages/sale/quotation/quotationMaintain', {
      stCleanQuotationMaintainState: 'cleanState',
    }),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchQuotations()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onCreateQuotation() {
      this.stCleanQuotationMaintainState()
      this.$router.push({ name: 'quotation-add' })
    },

    async showQuotationReadOnly(row) {
      const { id } = row.item
      this.$router.push({ name: 'quotation-read-only', params: { id } })
    },
  },
}
</script>

<style></style>

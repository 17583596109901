var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "quotation-id",
                          name: "quotationId",
                          label: _vm.$t("Id da cotação"),
                          type: "text",
                        },
                        model: {
                          value: _vm.filters.quotationId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "quotationId", $$v)
                          },
                          expression: "filters.quotationId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: { id: "quotation-store_id", required: false },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "quotation-price_table",
                          type: "vue-select",
                          label: _vm.$t("Tabela de preço"),
                          placeholder: _vm.$t("Selecione"),
                          options: _vm.getComboPriceTables,
                        },
                        model: {
                          value: _vm.filters.priceTableId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "priceTableId", $$v)
                          },
                          expression: "filters.priceTableId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "quotation-delivery",
                          name: "delivery",
                          label: _vm.$t("Entrega"),
                          placeholder: _vm.$t("Todos"),
                          type: "vue-select",
                          options: _vm.yesNoTypes(),
                        },
                        model: {
                          value: _vm.filters.isDelivery,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "isDelivery", $$v)
                          },
                          expression: "filters.isDelivery",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "5" } },
                    [
                      _c("e-person-search", {
                        attrs: {
                          id: "quotation-customer_id",
                          label: _vm.$t("Cliente"),
                          "is-customer": "",
                          "value-is-object": "",
                          "only-active": false,
                          "default-options": _vm.defaultCustomerOptions,
                        },
                        on: { input: _vm.setDefaultCustomerOptions },
                        model: {
                          value: _vm.filters.customer,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "customer", $$v)
                          },
                          expression: "filters.customer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "period",
                          type: "date-range-picker",
                          label: _vm.$t("Período criado"),
                        },
                        model: {
                          value: _vm.filters.createdDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "createdDate", $$v)
                          },
                          expression: "filters.createdDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.getData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "quotationTable",
            staticClass: "bordered",
            attrs: {
              id: "quotation-table",
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("QUOTATION.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.quotations,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(delivery)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.delivery, type: "yesNo" },
                    }),
                  ]
                },
              },
              {
                key: "cell(consigned)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.consigned, type: "yesNo" },
                    }),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "is-active": row.item.active,
                        "show-update": false,
                        "show-delete": false,
                        "show-activate": false,
                        "show-deactivate": false,
                        buttons: [
                          {
                            icon: "search",
                            variant: "primary",
                            title: _vm.$t("Visualizar cotação"),
                            event: "quotation-read-only",
                          },
                        ],
                      },
                      on: {
                        "quotation-read-only": function ($event) {
                          return _vm.showQuotationReadOnly(row)
                        },
                        update: function ($event) {
                          return _vm.showQuotationReadOnly(row)
                        },
                        delete: function ($event) {
                          return _vm.deletePriceTable(row)
                        },
                        activate: function ($event) {
                          return _vm.activateDeactivatePriceTable(row)
                        },
                        deactivate: function ($event) {
                          return _vm.activateDeactivatePriceTable(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "quotation-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("Create", "Quotation")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Cotação") },
            on: { click: _vm.onCreateQuotation },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }